import {MonthlyDataBannerT} from './monthlyData'

export type Timecard = {
  id: number
  date: string
  start: string
  end: string
  rest: number
  memo: string
  status: string
  device: string
}

export type MonthlyTimecard = Timecard & {
  created_dt: string
  date_csv: string
  diff: string
  total: string
  updated_dt: string
  user: number
}

export type TimecardChangeRequestReason = {
  start: string
  end: string
  comment: string
}

export type TimecardChangeRequestStatus = 'pending' | 'completed' | 'send_back'

export type TimecardChangeRequest = {
  id: number
  old_start: string | null
  new_start: string | null
  old_end: string | null
  new_end: string | null
  reason: TimecardChangeRequestReason
  status: TimecardChangeRequestStatus
  approved_by: number | null
  updated_dt: string | null
}

export type TimecardStatus = {
	id?: number
	status: "pending" | "completed" | "not_found"
  banner: MonthlyDataBannerT | null
}

export type TimecardChangeReason = {
  reasons: {
		start: string;
		end?: string;
		comment?: string;
	}
}

export type TimecardChangeT = TimecardChangeReason & {
  readonly date?: string
	readonly old_start: string
	readonly old_end?: string
	readonly old_start_display: string
	readonly old_end_display?: string
	new_start?: string
	new_end?: string
}

export const defaultTimecardChange: TimecardChangeT = {
  date: "",
	old_start: "",
	old_start_display: "",
	new_start: "",
	old_end: "",
	new_end: "",
	old_end_display: "",
	reasons: {
		start: "",
		end: ""
	}
}

export type TimecardMemoT = {
  readonly date: string
	memo: string
}

export const defaultTimecardMemo: TimecardMemoT = {
  date: "",
  memo: ""
}

export type TimecardChangeApplicationT = TimecardChangeT &{
  timecard: number
  created_dt: string
	status_display: string
	status: string
}

export type TimecardChangePendingApplicationT = TimecardChangeT & TimecardChangeApplicationT & {
  id: number
  new_start_display: string | null
  new_end_display: string | null
  requester_display: string
}