import {useParams} from 'react-router-dom'
import React, {useState} from 'react'
import {defaultMonthlyData, MonthlyDataT} from '../../store/monthlyData'
import {useFileDownload} from '../../hooks/useFileDownload'
import {useAuth} from '../../hooks/useAuth'
import useAuthAxios from '../../hooks/useAuthAxios'
import {MonthlyTimecard, TimecardChangeReason} from '../../store/timecard'
import {PTORequest} from '../../store/pto'
import {VacationType} from '../../store/company'

export type OpenDataNewT = { date: string, start: string, end: string } & TimecardChangeReason

export type OpenDataT = (MonthlyTimecard | OpenDataNewT) & { pto: Array<PTORequest & {new_date: string}> }

type OpenT = {
  open: boolean
  data: OpenDataT | null
}
export const useMonthlyWorkTime = () => {
  const {year, month, user_id} = useParams()
  const [data, setData] = useState<MonthlyDataT>(defaultMonthlyData)
  const [vacationTypes, setVacationTypes] = useState<VacationType[]>([])
  const {handleDownload} = useFileDownload()
  const {auth} = useAuth()
  const axios = useAuthAxios()
  const [open, setOpen] = useState<OpenT>({open: false, data: null})
  const [openChange, setOpenChange] = useState<{ open: boolean, id?: number, date?: string }>({open: false})

  React.useEffect(() => {
    axios.get(`timecard/monthly/${year}/${month}/${user_id ? user_id + '/' : ''}`)
      .then((res) => {
        let cp_data = res.data
        cp_data['insufficient'] = getTotalInsufficientHours(cp_data['workdays'])
        setData(cp_data)
      })
      .catch((error) => {
        if (error.response.status === 404) {
          setData(defaultMonthlyData)
        }
      })
  }, [year, month, axios, user_id])

  React.useEffect(() => {
    axios.get(`/companies/vacation-type/`)
      .then((res) => {
        setVacationTypes(res.data)
      })
  }, [axios])

  const csvDownload = () => {
    handleDownload(`timecard/monthly/${year}/${month}/${user_id !== null ? user_id : ''}/csv/`, 'empty.csv')
  }

  const getTotalInsufficientHours = (data: MonthlyTimecard[]) => {
    let minutes = 0
    let hours = 0
    data.forEach((val) => {
      if (val.diff[0] === '-') {
        let hourMinute: string[] = val.diff.slice(1).split(':')
        hours += parseInt(hourMinute[0])
        minutes += parseInt(hourMinute[1])
      }
    })
    hours += Math.trunc(minutes / 60)
    minutes = minutes % 60
    return `${hours}:${minutes}`
  }

  const handleCompleteClick = () => {
    axios.patch(`monthly/${data?.id}/complete/`)
      .then(
        (res) => {
          setData(res.data)
        },
      )
  }
  const handleOpenClick = (dayData: OpenDataT) => {
    const dataDate = new Date(`${dayData.date}T00:00:00.000+09:00`)
    const todayDate = new Date()
    if (auth.user.is_admin && user_id) {
      setOpen({open: true, data: dayData})
    } else if (data.status !== 'completed' && !user_id) {
      if (todayDate >= dataDate) {
        setOpen({open: true, data: dayData})
      }
    }
  }
  const handleMemo = () => {
    if (open.data && 'id' in open.data) {
      const openData = open.data
      axios.patch(`/timecard/memo/${openData.id}/`, {memo: openData.memo})
        .then(() => {
          let cp_data = data
          let index = data.workdays?.findIndex((val) => val.id === openData.id)
          if (index !== -1) {
            cp_data.workdays[index].memo = openData.memo
            setData(cp_data)
          }
          setOpen({open: false, data: null})
        })
        .catch(() => {
          setOpen({open: false, data: null})
        })
    }
  }
  const handleSavePto = (id: number, index: number) => {
    if (open.data) {
      axios.post(`/pto/change/${year}/${month}/${id}/`, {
        ...open.data.pto[index],
        date: open.data.date,
        new_date: open.data.pto[index].new_date || open.data.date,
      })
        .then((res) => {
          setData(res.data)
          setOpen({open: false, data: null})
        })
    }
  }
  const handleDeletePto = (id: number, index: number) => {
    if (open.data) {
      axios.post(`/pto/change/${year}/${month}/${id}/delete/`, {...open.data.pto[index], date: open.data.date})
      .then((res) => {
        setData(res.data)
        setOpen({open: false, data: null})
      })
    }
  }

  return {
    data,
    vacationTypes,
    csvDownload,
    handleCompleteClick,
    handleOpenClick,
    handleMemo,
    handleSavePto,
    handleDeletePto,
    user_id,
    open,
    setOpen,
    openChange,
    setOpenChange,
  }
}