import React, {useState} from 'react'
import {Box, Button} from '@mui/material'
import DescriptionIcon from '@mui/icons-material/Description'
import AccessTimeIcon from '@mui/icons-material/AccessTime'
import {TimecardStatus} from '../../../store/timecard'
import TimeCardMemo from './TimeCardMemo'
import {ModalBase, TimeCardChange} from '../../../components'

interface TimeCardAdditionalButtonsProps {
  status: TimecardStatus | null;
}

export const TimeCardAdditionalButtons: React.FC<TimeCardAdditionalButtonsProps> = ({status}) => {
  const [open, setOpen] = useState({memo: false, timeChange: false})
  const handleCloseMemo = () => {
    setOpen((prevState) => ({...prevState, memo: false}))
  }
  const handleCloseTimeChange = () => {
    setOpen((prevState) => ({...prevState, timeChange: false}))
  }
  const handleOpen = (name: 'memo' | 'timeChange') => {
    setOpen((prevState) => ({...prevState, [name]: true}))
  }

  return (
    <Box sx={{display: 'flex', justifyContent: 'center'}}>
      <Button
        sx={{
          mr: 2,
          display: 'flex',
          alignItems: 'center',
          p: 0,
          fontSize: 10,
          color: 'primary.contrastText',
        }}
        onClick={() => {
          handleOpen('memo')
        }}
        startIcon={<DescriptionIcon/>}
        disabled={status?.status === 'not_found'}
      >
        勤怠メモ
      </Button>
      <Button
        sx={{
          display: 'flex',
          alignItems: 'center',
          p: 0,
          fontSize: 10,
          color: 'primary.contrastText',
        }}
        onClick={() => {
          handleOpen('timeChange')
        }}
        startIcon={<AccessTimeIcon/>}
        disabled={status?.status === 'not_found'}
      >
        打刻時間の変更
      </Button>
      <ModalBase open={open.memo} handleClose={handleCloseMemo}>
        {status?.id && <TimeCardMemo id={status.id} handleClose={handleCloseMemo}/>}
      </ModalBase>

      <ModalBase open={open.timeChange} handleClose={handleCloseTimeChange}>
        {status?.id && <TimeCardChange id={status.id} handleClose={handleCloseTimeChange}/>}
      </ModalBase>

    </Box>
  )
}